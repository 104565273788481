import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home">
      {/* Header Section */}
      <header className="home-header">
        <h1 data-aos="fade-down">Budget To Budget</h1>
        <p data-aos="fade-up">Manage your budgets effortlessly</p>
      </header>

      {/* Description Section */}
      <section className="description">
        <div className="description-content" data-aos="fade-right">
          <p>Budget To Budget is your ultimate tool for managing personal finances. Track expenses, create custom budgets, and generate detailed reports to stay on top of your financial goals. With our intuitive interface, you can easily invite others to collaborate on shared budgets, making financial planning a breeze.</p>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="features">
        <h2 data-aos="fade-down">Key Features</h2>
        <div className="features-grid">
          <div className="feature-card" data-aos="zoom-in">
            <img src="01.png" alt="Track Expenses Screenshot" className="feature-img" />
            <p>Easily track your daily expenses with our intuitive interface.</p>
          </div>
          <div className="feature-card" data-aos="zoom-in" data-aos-delay="200">
            <img src="02.png" alt="Set Budgets Screenshot" className="feature-img" />
            <p>Create personalized budgets that fit your needs.</p>
          </div>
          <div className="feature-card" data-aos="zoom-in" data-aos-delay="400">
            <img src="03.png" alt="Generate Reports Screenshot" className="feature-img" />
            <p>Invite others to collaborate and manage shared budgets seamlessly.</p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta" data-aos="fade-up">
        <h2>Get the App</h2>
        <p>Download Budget To Budget from the App Store</p>
        <a href="https://apps.apple.com/us/app/budget-to-budget/id6502297557" className="cta-button">Download Now</a>
      </section>

      {/* User Testimonials Section */}
      <section className="testimonials">
        <h2 data-aos="fade-down">What Our Users Say</h2>
        <div className="testimonials-grid">
          <div className="testimonial" data-aos="fade-up" data-aos-delay="200">
            <p>"Every time I make a purchase, I open Budget To Budget. It has become a part of my routine!"</p>
            <h4>- Anonymous</h4>
          </div>
          <div className="testimonial" data-aos="fade-up" data-aos-delay="400">
            <p>"A must-have for anyone looking to take control of their budget."</p>
            <h4>- Penny</h4>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="home-footer" data-aos="fade-up">
        <p>&copy; 2023 Budget To Budget. All rights reserved.</p>
        <div className="footer-links">
          <a href="/privacy">Privacy Policy</a>
          <a href="/contact">Contact Us</a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
