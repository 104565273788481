import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

function Contact() {
  const formRef = useRef();

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
    contact_number: ''
  });

  useEffect(() => {
    generateContactNumber();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const generateContactNumber = () => {
    const contactNumber = Math.floor(Math.random() * 1000000).toString();
    setFormData((prevData) => ({
      ...prevData,
      contact_number: contactNumber
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data before submission:', formData); // Log form data
    emailjs.sendForm('service_8fn14i8', 'contact_form', formRef.current, 'Hueb5dzGomk1xbMeg')
      .then(() => {
        alert('Message sent successfully!');
        console.log('Message sent with data:', formData); // Log success
        setFormData({ user_name: '', user_email: '', message: '', contact_number: '' }); // Reset the form
        generateContactNumber(); // Generate a new contact number for the next submission
      }, (error) => {
        alert('Failed to send message. Please try again later.');
        console.log('FAILED...', error);
      });
  };

  return (
    <div className="contact main-content">
      <header className="contact-header">
        <h1>Contact Us</h1>
      </header>
      <section className="contact-content">
        <p>If you have any questions, feel free to reach out to us at <a href="mailto:support@budgettobudget.com">support@budgettobudget.com</a>.</p>
        <p>You can also fill out the contact form below, and we will get back to you as soon as possible.</p>
      </section>
      <section className="contact-form">
        <form ref={formRef} onSubmit={handleSubmit}>
          <input type="hidden" name="contact_number" value={formData.contact_number} />
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="user_name" value={formData.user_name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="user_email" value={formData.user_email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="5" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </section>
    </div>
  );
}

export default Contact;
