import React from 'react';
import PrivacyPolicy from '../components/PrivacyPolicy';

function Privacy() {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
}

export default Privacy;
