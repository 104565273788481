import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
  return (
    <Menu>
      <Link to="/" className="menu-item">Home</Link>
      <Link to="/about" className="menu-item">About</Link>
      <Link to="/privacy" className="menu-item">Privacy</Link>
      <Link to="/contact" className="menu-item">Contact</Link>
    </Menu>
  );
}

export default Sidebar;
