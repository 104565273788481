import React, { useEffect, useState } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/privacy-policy.html')
      .then(response => response.text())
      .then(data => {
        console.log(data); // Log fetched HTML content
        setHtmlContent(data);
      })
      .catch(error => console.error('Error fetching the HTML file:', error));
  }, []);

  return (
    <div className="privacy-policy-container">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default PrivacyPolicy;
