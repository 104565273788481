import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about main-content">
      <header className="about-header">
        <h1>About Us</h1>
      </header>
      <section className="about-content">
        <p>Budget to Budget is an app designed to help you manage your finances efficiently. Our mission is to provide an intuitive and easy-to-use platform for tracking expenses, setting budgets, and generating detailed financial reports.</p>
        <p>Developed by Diego Martinez, Budget to Budget aims to make financial planning accessible to everyone. With features like expense tracking, budget creation, and collaborative financial management, our app is designed to fit the needs of individuals and families alike.</p>
        <p>Join us on our journey to better financial health and start managing your budgets effortlessly with Budget to Budget.</p>
      </section>
      <section className="about-developer">
        <h2>About the Developer</h2>
        <p>Diego Martinez is a passionate software developer with a background in computer science from the University of California, Davis. With experience in mobile app development and a keen interest in financial technology, Diego has dedicated his skills to creating Budget to Budget to help users take control of their finances.</p>
      </section>
    </div>
  );
}

export default About;
